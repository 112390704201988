import React, { useState } from 'react';
import { useEffect } from 'react';
import { Package } from '../../../Screens/Scan/ScanInterface';
import styles from './logger.module.css';

export default function Logger({
  showAlert,
  loading,
  packageData,
}: {
  showAlert: boolean;
  loading: boolean;
  packageData: Package | undefined;
}) {
  const [style, setStyle] = useState<any>();

  useEffect(() => {
    if (loading) {
      setStyle('');
      return;
    }
    if (!!packageData) {
      setStyle(styles.success);
      return;
    }
    if (showAlert) {
      setStyle(styles.alert);
      return;
    }
  }, [loading, showAlert, packageData]);

  return (
    <div className={`${styles.block} ${style}`}>
      <span></span>{' '}
      {showAlert && !!packageData
        ? 'El paquete existe pero la info del mismo podría ser erronea.'
        : ''}
    </div>
  );
}
