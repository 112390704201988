//@ts-ignore
import React from 'react';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import StatusBlock from '../../atoms/StatusBlock/StatusBlock';
import { Package } from '../../../Screens/Scan/ScanInterface';
import { sendPrint } from '../../../common/integrations/JSPrintManager/jspmSendPrint';
import styles from './PackageInfo.module.css';
import { config } from './../../../config';

//@ts-ignore
const packageStatusF = (id: string) => {
  switch (id) {
    case '1':
      return 'created';
    case '2':
      return 'in_order';
    case '3':
      return 'collected';
    case '4':
      return 'ready_to_storage';
    case '5':
      return 'ready_to_delivery';
    case '6':
      return 'delivered';
    case '7':
      return 'to_storage';
    case '8':
      return 'to_next_visit';
    case '9':
      return 'visits_done';
    case '10':
      return 'storaged';
    case '11':
      return 'lost';
    case '12':
      return 'canceled_visit';
    case '13':
      return 'deleted';
    case '14':
      return 'suspend_visit';
    case '15':
      return 'returned';
    case '16':
      return 'queued';
    case '17':
      return 'to_return';
    case '18':
      return 'canceled_client_test';
    case '19':
      return 'canceled_client_request';
    case '20':
      return 'canceled_package_not_found';
    case '21':
      return 'canceled_testing';
    case '22':
      return 'canceled_duplicated_package';
    case '23':
      return 'in_pre_order';
    case '24':
      return 'out_pre_order';
    case '25':
      return 'queued_for_routing';
    case '99':
      return 'audit';
    default:
      return '';
  }
};

interface PackageComponentProps {
  packageData: Package | undefined;
  printerName: string;
}

export default function PackageInfo({
  packageData,
  printerName,
}: PackageComponentProps) {
  const { legacyRoute } = config;

  const formatDate = (date: string | undefined) => {
    if (!date) {
      return '';
    }
    dayjs.extend(LocalizedFormat);
    const auxDate = dayjs(date).format('DD/MM/YYYY');
    const auxTime = dayjs(date).format('HH:mm');
    return `${auxDate} - ${auxTime}`;
  };

  function dataParser(value: string | number | null | undefined) {
    return value === null || value === 'None' || value === ''
      ? 'Missing'
      : value;
  }

  const valueConverter = (
    value: string | undefined,
    addMiddleDash?: boolean,
    ignoreParse?: boolean,
  ) => {
    if (ignoreParse && (!value || `${value}` === 'null' || value === '')) {
      return '';
    }
    return addMiddleDash ? `- ${dataParser(value)}` : `${dataParser(value)}`;
  };

  const handlePrinterClick: () => void = () => {
    if (printerName && packageData?.trackingNumber) {
      sendPrint(packageData, printerName);
    } else {
      console.log('error', packageData, printerName);
    }
  };

  let route = legacyRoute ? packageData?.legacyRouteId : packageData?.routeId;
  route = route ? `${dataParser(route)}` : '';

  return (
    <div>
      {/* package Info */}
      <div>
        {/* upper blocks */}
        <div className={styles.upperBlocks}>
          {/* blue */}
          <div className={`${styles.guideBlock}  ${styles.blocks}`}>
            <button onClick={handlePrinterClick} className={styles.printButton}>
              🖨️
            </button>
            {packageData?.trackingNumber
              ? `${dataParser(packageData?.trackingNumber)} / ${dataParser(
                  packageData?.referenceId,
                )} (${
                  valueConverter(packageData?.multiguideSequence || '') || '-'
                })
                `
              : '-'}
            <span>Guia ivoy / Referencia</span>
          </div>
          <div className={styles.subBlocks}>
            {/* lg blue */}
            <div className={styles.blocks}>
              {route
                ? `${dataParser(route.slice(-4))} (${valueConverter(
                    packageData?.routeSequence,
                  )})`
                : '-'}
              <span>Orden</span>
            </div>
            {/* purple */}
            <div className={styles.blocks}>
              {packageData?.zoneHub
                ? `${dataParser(packageData?.zoneHub) || ''} ${valueConverter(
                    packageData?.zoneCoverage,
                    true,
                    true,
                  )}`
                : '-'}
              <span>Destino</span>
            </div>
          </div>
        </div>
        {/* under blocks */}
        <div>
          {/* states */}
          <StatusBlock
            title="Estado de la orden"
            value={`${packageData?.routeStatusName?.toUpperCase() || ''}`}
          />
          {true && (
            <StatusBlock
              title="Estado del paquete"
              value={
                packageStatusF(
                  packageData?.deliveryStatusId || '',
                )?.toUpperCase() || ''
              }
            />
          )}
          {false && (
            <StatusBlock
              title="Cliente"
              value={`${packageData?.clientName || ''}`}
            />
          )}
          {false && (
            <StatusBlock
              title="Cancelación"
              value={`${
                packageData?.cancelationReasonName?.toUpperCase() || ''
              }`}
            />
          )}
          {true && (
            <StatusBlock
              title="Fecha de la Orden"
              value={`${formatDate(packageData?.routeDateCreated) || ''}`}
            />
          )}
        </div>
      </div>
    </div>
  );
}
