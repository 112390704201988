//@ts-ignore
import JSPM from 'jsprintmanager';
import { PrintersData } from '../printerInterfaces';

JSPM.JSPrintManager.auto_reconnect = true;
JSPM.JSPrintManager.license_url = process.env.REACT_APP_LICENSE_URL;
JSPM.JSPrintManager.start();

function jspmWSStatus() {
  try {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
      alert(
        'JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm',
      );
      return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
      alert('JSPM has blocked this website!');
      return false;
    }
  } catch (e) {
    console.error('Error in jspmWSStatus', e);
  }
}

//Do printing...
export function JSPMPrint(printData: PrintersData) {
  if (jspmWSStatus()) {
    //Create a ClientPrintJob
    const cpj = new JSPM.ClientPrintJob();
    const [ip, port] = printData?.printer?.split(':');

    cpj.clientPrinter = printData?.printer?.includes(':')
      ? new JSPM.NetworkPrinter(parseInt(port), ip)
      : new JSPM.InstalledPrinter(printData.printer);

    let zpl =
      '^XA' +
      '^FO500,50^GFA,200,200,5,,::::::::K06,K0F,K0F8,:K0A,J026,J0F8,I03FC078,I07FD008,001C3D87C,00783DF3C,00703C7FC,J07C07C,J07807C,:J0F4,J0EF,I01EF8,0071C1C8,01FB8078,I0F,I02,,::::::::^FS' +
      '^FO275,30^GB287,172,3^FS' +
      '^CFA,45' +
      '^FO295,50^FD' +
      printData.order +
      '^FS' +
      '^CFA,30' +
      '^FO435,60^FD' +
      printData.zone +
      '  ^FS';

    if (printData.guide !== printData.guideClient && printData.guideClient) {
      zpl += '^CFA,22' + '^FO295,105^FD' + printData.guide + '^FS';

      zpl += '^CFA,22' + '^FO295,130^FD' + printData.guideClient + '^FS';
    } else {
      zpl += '^CFA,22' + '^FO295,120^FD' + printData.guide + '^FS';
    }

    zpl += '^CFA,45' + '^FO295,160^FD' + printData.sequence + '^FS2';

    zpl += '^CFA,25' + '^FO450,170^FD' + printData.subseq + '^FS2' + '^XZ';

    //Set content to print...
    //Create Zebra ZPL commands for sample label
    cpj.printerCommands = zpl;
    //Send print job to printer!
    cpj.sendToClient();
  }
}
