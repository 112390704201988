import React from 'react';
import styles from './StatusBlock.module.css';

export default function StatusBlock({
  title,
  value,
}: {
  title: string;
  value: string;
}) {
  return (
    <div className={styles.statusBlock}>
      <div className={styles.redCircle} />
      {title}
      <div className={styles.statusValue}>{value || '-'}</div>
    </div>
  );
}
