const validateBool = (env: string | undefined): boolean => {
  return env === 'true';
};

export const config = {
  url: process.env.REACT_APP_ENDPOINT_URL,
  altUrl: process.env.REACT_APP_ALT_ENDPOINT_URL,
  altRequest: validateBool(process.env.REACT_APP_ALT_REQUEST),
  legacyRoute: validateBool(process.env.REACT_APP_LEGACY_ROUTE),
  deliveryStatusUrl: process.env.REACT_APP_DELIVERY_STATUS_URL,
};
