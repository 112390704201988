import React, { useEffect, useState } from 'react';
//@ts-ignore
import JSPM from 'jsprintmanager';

import { PrinterData } from '../../../Screens/Scan/ScanInterface';
import { InstalledPrinter, StatePrinters, DirInput } from './printerInterface';
import { ipRegex, portRegex } from '../../../common/constants';
import styles from './printers.module.css';
// import { reloadIcon } from '../../../common/assets';
import reloadIcon from '../../../common/assets/icons/reload-svgrepo-com.svg';

interface PrintersProps {
  setPrinterData: ({ autoPrint, printerName }: PrinterData) => void;
}

export default function Printers({ setPrinterData }: PrintersProps) {
  const isNetworkPrintingAvailable = true;
  const [printMethod, setPrinterMethod] = useState<
    'Installed' | 'Network' | ''
  >('');
  const [printers, setPrinters] = useState<StatePrinters>({
    installed: [],
    network: [],
  });
  const [dirInput, setDirInput] = useState<DirInput>({
    ip: '',
    port: '',
  });
  const [selectedPrinter, setSelectedPrinter] = useState<string>('');
  const [autoPrint, setAutoprint] = useState<boolean>(false);

  const loadPrinters = () => {
    JSPM.JSPrintManager.getPrintersInfo().then(
      (installedPrinters: InstalledPrinter[]) => {
        const validPrinters = installedPrinters
          .map((printer: InstalledPrinter) => printer.name)
          .filter((printerName: string) => printerName.startsWith('wo-'));

        setPrinters({
          ...printers,
          installed: validPrinters,
        });
      },
    );
  };

  useEffect(() => {
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start().then(() => {
      loadPrinters();
    });
  }, [printMethod]);

  useEffect(() => {
    setPrinterData({
      autoPrint: autoPrint,
      printerName: selectedPrinter,
    });
  }, [selectedPrinter, autoPrint, setPrinterData]);

  const handlePrintMethodChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value: string = event.target.value;
    if (value === '' || value === 'Installed' || value === 'Network') {
      setPrinterMethod(value);
      setAutoprint(false);
      setSelectedPrinter('');
    }
  };

  const handleInstalledPrinterChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value: string = event.target.value;
    setSelectedPrinter(value);
  };

  const handleNetworkPrinterChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name: string = event.target.name;
    const value: string = event.target.value;
    setDirInput({
      ...dirInput,
      [name]: value,
    });
  };

  const handleButtonClick: () => void = () => {
    let printerValue = '';
    if (
      !selectedPrinter &&
      ipRegex.test(dirInput.ip) &&
      portRegex.test(dirInput.port)
    ) {
      printerValue = `${dirInput.ip}:${dirInput.port}`;
    }

    setSelectedPrinter(printerValue);
    setAutoprint(false);
  };

  const handleReloadClick = () => {
    loadPrinters();
    setSelectedPrinter('');
    setAutoprint(false);
  };

  return (
    <div className={styles.printersContainer}>
      <div className={styles.block}>
        <div className={styles.inputBlock}>
          <label>Tipo de impresora</label>
          <select
            name="PrintMode"
            value={printMethod}
            onChange={handlePrintMethodChange}
            id="mode"
          >
            <option value="" disabled hidden>
              Selecciona un tipo de impresora
            </option>
            <option value="Installed">Instalada</option>
            {isNetworkPrintingAvailable && (
              <option value="Network">IP/Ethernet</option>
            )}
          </select>
        </div>
      </div>

      {printMethod === 'Installed' && (
        <div className={styles.block}>
          <div className={styles.inputBlock}>
            <label>Impresoras Instaladas</label>
            <select
              value={selectedPrinter}
              id="printer"
              onChange={handleInstalledPrinterChange}
            >
              <option value="" disabled hidden>
                Selecciona una impresora
              </option>
              {printers.installed?.map((printer: string, index: number) => (
                <option value={printer} key={index}>
                  {printer}
                </option>
              ))}
            </select>
          </div>
          <button className={styles.reload} onClick={handleReloadClick}>
            <img src={reloadIcon} alt="reload" />
          </button>
        </div>
      )}
      {printMethod === 'Network' && (
        <div className={styles.block}>
          <div className={styles.inputBlock}>
            <label>IP</label>
            <input
              type="text"
              value={dirInput.ip}
              placeholder="0.0.0.0"
              disabled={!!selectedPrinter}
              name="ip"
              onChange={handleNetworkPrinterChange}
            />
          </div>
          <div className={styles.inputBlock}>
            <label>Port</label>
            <input
              type="text"
              value={dirInput.port}
              placeholder="0000"
              name="port"
              disabled={!!selectedPrinter}
              onChange={handleNetworkPrinterChange}
            />
          </div>
          <div className={styles.inputBlock}>
            <button
              onClick={handleButtonClick}
              className={`${styles.validateButton} ${
                !!selectedPrinter ? styles.redBorder : styles.greenBorder
              }`}
            >
              {!!selectedPrinter ? '❌' : '✔️'}
            </button>
          </div>
        </div>
      )}
      {!!selectedPrinter && (
        <div className={styles.block}>
          <div className={styles.inputBlock}>
            <label>Autoimprimir</label>
            <div
              className={`${styles.switch} ${
                autoPrint ? styles.switchGreen : styles.switchRed
              }`}
              onClick={() => {
                setAutoprint((oldValue: boolean) => !oldValue);
              }}
            >
              <span className={styles.slider}></span>
              <input type="checkbox" checked={autoPrint} readOnly />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
