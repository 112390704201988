import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';

import PackageInfo from '../../Components/molecules/PackageInfo/PackageInfo';
import Printers from '../../Components/molecules/Printers/Printers';
import { Sound } from '../../common/Sound/sound';
import { Package, PrinterData } from './ScanInterface';
import { sendPrint } from '../../common/integrations/JSPrintManager/jspmSendPrint';
import { config } from '../../config';
import styles from './Scan.module.css';
import Logger from '../../Components/molecules/Logger/Logger';
import { useRef } from 'react';

export default function Scan() {
  const { legacyRoute } = config;
  const inputRef = useRef(null);
  const [packageData, setPackageData] = useState<Package | undefined>();
  const [packageId, setPackageId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [printer, setPrinter] = useState<PrinterData>({
    autoPrint: false,
    printerName: '',
  });

  const SoundFactory = (string: string) => {
    return useMemo(() => {
      return new Sound(`assets/sound/${string}.mp3`);
    }, [string]);
  };

  const HMX1Sound = SoundFactory('HMX1Sound');
  const HMX2Sound = SoundFactory('Sound2');
  const GDLSound = SoundFactory('GDLSound');
  const QROSound = SoundFactory('QROSound');
  const TOLSound = SoundFactory('TOLSound');
  const PUESound = SoundFactory('PUESound');
  const scanningSound = SoundFactory('scanning');
  const errorSound = SoundFactory('error');

  const soundHandler: (zoneHub: string | undefined) => void = (
    zoneHub: string | undefined,
  ) => {
    switch (zoneHub) {
      case 'HMX1':
        HMX1Sound.play();
        break;
      case 'HMX2':
        HMX2Sound.play();
        break;
      case 'TOL':
        TOLSound.play();
        break;
      case 'GDL':
        GDLSound.play();
        break;
      case 'QRO':
        QROSound.play();
        break;
      case 'PLA':
        PUESound.play();
        break;
      case 'PLA1':
        PUESound.play();
        break;
      case 'ERR':
        errorSound.play();
        break;
      default:
        scanningSound.play();
        break;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPackageId(value);
  };

  const hasData = (data: Package | undefined) => {
    if (legacyRoute) {
      return (
        (!!data && !!data?.guide) ||
        data?.guide_ivoy ||
        data?.id_package ||
        data?.id_package_status
      );
    }
    return !!data && !!data?.routeId;
  };

  const isValidData = (data: Package | undefined) => {
    return !!data && !!data.multiguideSequence;
  };

  // PRINTABLE
  //const printable = () => {};

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleInputPress = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (event.key === 'Enter' && !!packageId && !loading) {
      let data: any;

      //fetch data to primary endpoint
      try {
        setPackageData(undefined);
        setShowAlert(false);
        setLoading(true);

        data = await axios
          .get(`${config.url}${encodeURIComponent(packageId.toString())}`, {
            headers,
          })
          .then(async (data) => {
            return hasData(data.data) ? data.data : undefined;
          });
      } catch (error) {
        console.log('Falla en principal', error);
      }

      if (config.altRequest && !isValidData(data)) {
        try {
          if (process.env.NODE_ENV !== 'production') {
          }
          data = await axios
            .get(
              `${config.altUrl}${encodeURIComponent(packageId.toString())}`,
              {
                headers,
              },
            )
            .then(async (data) => {
              return hasData(data.data || {}) ? data.data : undefined;
            });
        } catch (error) {
          console.log('Falla en respaldo');
        }
      }

      if (hasData(data || {}) ? data : undefined) {
        data.referenceId = data.referenceId || data.guide;
        data.trackingNumber = data.trackingNumber || data.guide_ivoy;
        data.deliveryStatusId = data.id_package_status;
        data.routeSequence =
          data?.sequence && data.totalPoints
            ? `${data.sequence - 1}/${data.totalPoints - 1}`
            : '';
      }
      setPackageData(hasData(data || {}) ? data : undefined);
      setPackageId('');
      if (!data) {
        setShowAlert(true);
        soundHandler('ERR');
        setLoading(false);
        return;
      }

      data.zoneHub = data?.zoneHub || data?.zoneName;
      // send feedback
      if (hasData(data || {})) {
        soundHandler(data?.zoneHub);
        console.log(data);
        console.log(printer);
        if (
          printer.autoPrint &&
          !!data.routeId &&
          data?.id_package_status === '2'
        ) {
          sendPrint(data, printer.printerName);
        }
      } else {
        soundHandler('ERR');
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      //@ts-ignore
      inputRef?.current?.focus();
    }
  }, [loading]);

  return (
    <div>
      <input
        type="text"
        onChange={handleInputChange}
        onKeyDown={handleInputPress}
        value={packageId}
        className={styles.idInput}
        disabled={loading}
        autoFocus
        ref={inputRef}
      />
      <Logger
        showAlert={showAlert}
        loading={loading}
        packageData={packageData}
      />

      <PackageInfo
        packageData={packageData}
        printerName={printer.printerName}
      />
      <Printers setPrinterData={setPrinter} />
    </div>
  );
}
