import './App.css';
import Scan from './Screens/Scan/Scan';

function App() {
  return (
    <div className="App">
      <Scan />
    </div>
  );
}

export default App;
