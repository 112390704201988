import { JSPMPrint } from './jspmPrint';
import { PrintersData } from '../printerInterfaces';
import { Package } from '../../../Screens/Scan/ScanInterface';

export async function sendPrint(packageInfo: Package, printer: string) {
  console.log(packageInfo);
  const {
    trackingNumber: guide,
    routeId,
    legacyRouteId,
    referenceId,
    multiguideSequence,
  } = packageInfo;
  const routeSequence = packageInfo?.routeSequence || '-';
  const route = legacyRouteId
    ? `${legacyRouteId}`.slice(`${legacyRouteId}`.length - 4)
    : '-';

  const data: PrintersData = {
    printer: printer || '',
    order: route,
    guide: guide || '',
    guideClient: referenceId || '',
    total: 0,
    subseq: multiguideSequence || '',
    sequence: routeSequence || '',
    zone: packageInfo.zoneName || '',
  };

  JSPMPrint(data);
}
